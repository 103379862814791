import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ApiBaseUrl,
  getProjectApi,
  getProjectListApi,
} from "../utils/Constants";

export const getProject = createAsyncThunk("getProject", async (projectId) => {
  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
    };

    const response = await axios.get(
      ApiBaseUrl + getProjectApi + projectId,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

const GetProjectSlice = createSlice({
  name: "getProjectReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearDataGetProject: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getProject.rejected, (state) => {
        state.isError = false;
      });
  },
});

export const { clearDataGetProject } = GetProjectSlice.actions;
export default GetProjectSlice.reducer;
