import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/GetProfileSlice";
import {
  clearEditProfileData,
  editProfile,
} from "../../redux/EditProfileSlice";
import { useNavigate } from "react-router-dom";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material";
import ReactPasswordChecklist from "react-password-checklist";

const EditProfile = () => {
  const [profileData, setProfileData] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const profileResponse = useSelector((state) => state.getProfileReducer.data);
  const editProfileResponse = useSelector(
    (state) => state.editProfileReducer.data
  );

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  useEffect(() => {
    if (profileResponse != null && profileResponse.status == 1) {
      setProfileData(profileResponse.data);
    }
  }, [profileResponse]);

  const updateProfile = () => {
    if (profileData?.firstName.length < 1) {
      alert("Please enter first name");
    }
    // else if (profileData?.lastName.length < 1) {
    //   alert("Please enter last name");
    // } else if (profileData?.companyName.length < 1) {
    //   alert("Please enter company name");
    // }
    // else if (password.length < 1) {
    //   alert("Please enter password");
    // } else if (confirmPassword.length < 1) {
    //   alert("Please enter confirm password");
    // } 
    else if (password != confirmPassword) {
      alert("Password does not match");
    } else {
      const payload = {
        firstName: profileData?.firstName,
        lastName: profileData?.lastName,
        companyName: profileData?.companyName,
        email: profileData?.email,
        password: password,
      };
      dispatch(editProfile(payload));
    }
  };

  console.log("profileData", profileData);
  useEffect(() => {
    if (editProfileResponse != null && editProfileResponse.status == 1) {
      alert("Profile updated successfully");
      navigation(-1);
      dispatch(clearEditProfileData());
    }
  }, [editProfileResponse]);

  console.log("profileData", profileData);
  return (
    <div className="inner contact_us sidebar_mar">
      <div className="signup_bg" style={{ margin: "30px 0" }}>
        <div className="text_btn"></div>
        <div className="signup_form">
          <Form>
            <h3>Edit Profile</h3>
            <div className="d_flex">
              <Form.Group
                className="mb-3 form_group"
                controlId="formBasicEmail"
              >
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First name"
                  className="createInput"
                  value={profileData?.firstName}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      firstName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group
                className="mb-3 form_group"
                controlId="formBasicEmail"
              >
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last name"
                  className="createInput"
                  value={profileData?.lastName}
                  onChange={(e) =>
                    setProfileData({ ...profileData, lastName: e.target.value })
                  }
                />
              </Form.Group>
            </div>
            <Form.Group
              className="mb-3 form_group"
              style={{ marginBottom: 12 }}
              controlId="formBasicEmail"
            >
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email Address"
                className="createInput"
                value={profileData?.email}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    email: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              className="mb-3 form_group"
              style={{ marginBottom: 12 }}
              controlId="formBasicEmail"
            >
              <Form.Label>Set New Password</Form.Label>
              <div className="inline">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="createInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="passwod_btn"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <VisibilityTwoTone />
                  ) : (
                    <VisibilityOffTwoTone />
                  )}
                </button>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3 form_group"
              style={{ marginBottom: 12 }}
              controlId="formBasicEmail"
            >
              <Form.Label>Confirm New Password</Form.Label>
              <div className="inline">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="createInput"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="passwod_btn"
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  {showConfirmPassword ? (
                    <VisibilityTwoTone />
                  ) : (
                    <VisibilityOffTwoTone />
                  )}
                </button>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 form_group" controlId="formBasicEmail">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                placeholder="Company"
                className="createInput"
                value={profileData?.companyName}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    companyName: e.target.value,
                  })
                }
              />
            </Form.Group>
            {password.length > 0 && confirmPassword.length > 0 && (
              <ReactPasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={5}
                value={password}
                valueAgain={confirmPassword}
                onChange={(isValid) => {}}
                style={{ marginTop: 10 }}
              />
            )}
            <div className="next_btn">
              <Button onClick={updateProfile} style={{ marginTop: 10 }}>
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
