import React, { useState, useEffect } from "react";
import { ApiBaseUrl, pdfUploadApiDrive } from "../../utils/Constants";
import axios from "axios";
// Google API and Identity Services URLs
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/drive/v2/rest";
const SCOPES = "https://www.googleapis.com/auth/drive.metadata.readonly";

// TODO: Replace with your own Client ID and API Key
const CLIENT_ID =
  // '867962912534-gd20ess8nki6oh5kiubon15rjs2n18v2.apps.googleusercontent.com'
  "195779094131-ip4ppi06hggin03e4r27ii1jmjq4b89e.apps.googleusercontent.com";
const API_KEY =
  // 'AIzaSyD3w69rPvJAKOsRv6pu9PXcNpnotji38Ik'
  "AIzaSyDiM0qX4mjaHb6zdKV6QZMFWSrgNPES13M";
const DriveAPIQuickstart = () => {
  const [gapiInited, setGapiInited] = useState(false);
  const [gisInited, setGisInited] = useState(false);
  const [tokenClient, setTokenClient] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [files, setFiles] = useState([]);
  const [isSignedIn, setIsSignedIn] = useState(false);

  // Load GAPI and GIS scripts
  useEffect(() => {
    const loadGapiScript = () => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.async = true;
      script.defer = true;
      script.onload = gapiLoaded;
      document.body.appendChild(script);
    };

    const loadGisScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = gisLoaded;
      document.body.appendChild(script);
    };

    loadGapiScript();
    loadGisScript();
  }, []);

  const gapiLoaded = () => {
    window.gapi.load("client", initializeGapiClient);
  };

  const gisLoaded = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: "", // callback will be defined in handleAuthClick
    });
    setTokenClient(client);
    setGisInited(true);
  };

  const initializeGapiClient = async () => {
    await window.gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    setGapiInited(true);
  };

  useEffect(() => {
    if (gapiInited && gisInited) {
      // Both GAPI and GIS have loaded, enable the "Authorize" button
    }
  }, [gapiInited, gisInited]);

  const handleAuthClick = () => {
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      setIsSignedIn(true);
      await listFiles();
    };

    if (window.gapi.client.getToken() === null) {
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      tokenClient.requestAccessToken({ prompt: "" });
    }
  };

  const handleSignoutClick = () => {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      window.google.accounts.oauth2.revoke(token.access_token);
      window.gapi.client.setToken("");
      setFiles([]);
      setIsSignedIn(false);
    }
  };

  const listFiles = async () => {
    try {
      const response = await window.gapi.client.drive.files.list({
        pageSize: 10,
        // fields: 'files(id, name)',
        fields: "*",
        q: "mimeType='application/pdf'", // Filter for Google Docs
      });
      console.log("response", response);
      const files = response.result.items;
      setFiles(files || []);
      setAccessToken(window.gapi.client.getToken());
      //   console.log("files", files)
      //   console.log("tokenClient", tokenClient)
      console.log(
        "window.gapi.client.getToken()",
        window.gapi.client.getToken()
      );
    } catch (err) {
      console.error("Error listing files:", err.message);
    }
  };

  const handleFileAction = async (fileId) => {
    // setLoading(true);
    let data = JSON.stringify({
      fileId: fileId,
      accessToken: accessToken?.access_token,
      projectId: "66b4597debcc5a7c9401b31f",
    });
    axios
      .post(ApiBaseUrl + pdfUploadApiDrive, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        data: data,
      })
      .then((response) => {
        console.log(response);
        // setShowOptionList(false);
        alert(
          "File uploaded successfully!, Now you can start start the question from the pdf"
        );

        // if (response.data && onPdfUploadSuccess) {
        //   onPdfUploadSuccess(response.data);
        // }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  return (
    <>
      {/* <div className="over_s"> */}
      {/* <h1>Drive API Quickstart</h1>
             <center>
            <button
                id="authorize_button"
                onClick={handleAuthClick}
                style={{ visibility: gapiInited && gisInited ? 'visible' : 'hidden' }}
            >
                {isSignedIn ? 'Refresh' : 'Authorize'}
            </button>
             </center>
            <button
                id="signout_button"
                onClick={handleSignoutClick}
                style={{ visibility: isSignedIn ? 'visible' : 'hidden' }}
            >
                Sign Out
            </button> */}
      {/* <pre id="content">
        {files.length > 0
          ? `Files:\n${files.map((file) => `${file.name} (${file.id})\n`).join('')}`
          : 'No files found.'}
      </pre> */}
      {/* <pre id="content">
                {files.length > 0 ? (
                    <div className='op d-block'>
                        Files:
                        {files.map((file) => (
                            <div key={file.id}>
                                {file.originalFilename} ({file.id}){' '}
                                <button onClick={() => handleFileAction(file.id)}>select</button>
                            </div>
                        ))}
                    </div>
                ) : (
                    'No files found.'
                )}
            </pre> */}

      {/* </div> */}
    </>
  );
};

export default DriveAPIQuickstart;
