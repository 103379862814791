import "../src/assets/style/Style.scss";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import SignUp from "./component/SignUp/SignUp";
import SignIn from "./component/SignIn/SignIn";
import Otp from "./component/Otp/Otp";
import CreateProfile from "./component/CreateProfile/CreateProfile";
import Sidebar from "./component/Sidebar/Sidebar";
import { Provider } from "react-redux";
import store from "./redux/store";
import CreateProject from "./component/CreateProject";
import Home from "./component/Home/Home";
import YourAi from "./component/YourAi/YourAi";
import Project from "./component/Project/Project";
import About from "./component/About/About";
import Integrations from "./component/Integrations/Integrations";
import Documents from "./component/Documents/Documents";
import ProjectEdit from "./component/ProjectEdit/ProjectEdit";
import ProfileHeader from "./component/ProfileHeader/ProfileHeader";
import ContactUs from "./component/ContactUs/ContactUs";
import GoogleDrive from "./component/googleDrive";
import PrivateRoute from "./component/PrivateRoute/PrivateRoute";
import Company from "./component/Company/Company";
import AddCompany from "./component/AddCompany/AddCompany";
import Header from "./component/Header/Header";
import HeaderHome from "./component/HeaderHome/HeaderHome";
import Footer from "./component/Footer/Footer";
import HowWork from "./component/HowWork/HowWork";
import Feature from "./component/Feature/Feature";
import BusinessRelation from "./component/BusinessRelation/BusinessRelation";
import PrivacyPolicy from "./component/PrivacyPolicy/PrivacyPolicy";
import TeamCondition from "./component/TermCondition/TermCondition";
import TermCondition from "./component/TermCondition/TermCondition";
import NotFound from "./component/NotFound/NotFound";
import EditProfile from "./component/EditProfile/EditProfile";
import ProjectEditId from "./component/ProjectEditId/ProjectEditId";

function App() {
  function SidebarCommon() {
    return (
      <>
        <div className="">
          <Outlet />
          <Sidebar />
        </div>
      </>
    );
  }

  function HomeSection() {
    return (
      <>
        <div className="home_page">
          <div className="coustom_container">
            <HeaderHome />
            <Outlet />
          </div>
          <Footer />
        </div>
      </>
    );
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route element={<HomeSection />}>
            <Route path="/" element={<Home />} />
            <Route path="how_work" element={<HowWork />} />
            <Route path="feature" element={<Feature />} />
            <Route path="business_relation" element={<BusinessRelation />} />
            <Route path="privacy_policy" element={<PrivacyPolicy />} />
            <Route path="term_conditon" element={<TermCondition />} />
          </Route>
          <Route path="SignIn" element={<SignIn />} />
          <Route path="SignUp" element={<SignUp />} />
          <Route path="Otp" element={<Otp />} />
          <Route path="CreateProfile" element={<CreateProfile />} />
          <Route path="Sidebar" element={<Sidebar />} />

          <Route
            element={
              <PrivateRoute>
                <SidebarCommon />
              </PrivateRoute>
            }
          >
            <Route path="/Dashboard" element={<ProjectEdit />} />
            <Route path="YourAi" element={<YourAi />} />
            <Route path="Integrations" element={<Integrations />} />
            <Route path="Project" element={<Project />} />
            <Route path="Documents" element={<Documents />} />
            <Route path="CreateProject" element={<CreateProject />} />
            <Route path="About" element={<About />} />
            <Route path="ProfileHeader" element={<ProfileHeader />} />
            <Route path="ContactUs" element={<ContactUs />} />
            <Route path="GoogleDrive" element={<GoogleDrive />} />
            <Route path="Company" element={<Company />} />
            <Route path="AddCompany" element={<AddCompany />} />
            <Route path="EditProfile" element={<EditProfile />} />
            <Route path="/project/edit/:id" element={<ProjectEditId />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
