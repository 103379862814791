import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, getDocumentListApi } from "../utils/Constants";

export const getDocumentList = createAsyncThunk("getDocumentList", async () => {
  try {
    const token = localStorage.getItem("token");
    console.log("Token", token);
    const config = {
      headers: { Authorization: token },
    };
    const response = await axios.get(ApiBaseUrl + getDocumentListApi, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

const GetDocumentListSlice = createSlice({
  name: "getDocumentListReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearDataDocumentList: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocumentList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDocumentList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const { clearDataDocumentList } = GetDocumentListSlice.actions;

export default GetDocumentListSlice.reducer;
