import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, askQuestionFromPdfApi } from "../utils/Constants";

export const askQuestionFromPdf = createAsyncThunk("askQuestionFromPdf", async (payload) => {
    try {
      const token = localStorage.getItem("token");
      console.log("Token", token);
      const config = {
        headers: { Authorization: token },
      };
      console.log("payload Question from PDF ===>", payload);
  
      const response = await axios.post(
        ApiBaseUrl + askQuestionFromPdfApi,
        payload,
        config
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  });
  
  const AskQuestionFromPdfSlice = createSlice({
    name: "askQuestionFromPdfReducer",
  
    initialState: {
      isLoading: false,
      data: null,
    },
    reducers: {
      clearAskQuestionFromPdf: (state) => {
        state.data = null;
        state.isAuthenticated = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(askQuestionFromPdf.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(askQuestionFromPdf.fulfilled, (state, action) => {
          state.isLoading = false;
          state.data = action.payload;
        })
        .addCase(askQuestionFromPdf.rejected, (state) => {
          state.isLoading = false;
          state.isError = true;
        });
    },
  });
  
  export const { clearAskQuestionFromPdf } = AskQuestionFromPdfSlice.actions;
  export default AskQuestionFromPdfSlice.reducer;