import React, { useEffect, useState } from "react";
import "./SignIn.css";
import ai_img from "../../assets/img/ai_img.svg";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import google from "../../assets/img/google.svg";
import facebook from "../../assets/img/facebook.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { clearDataSignUp, signupUser } from "../../redux/signupSlice";
import {
  clearGoogleLoginData,
  googleLogin,
} from "../../redux/GoogleSignInSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { setUser } from "../../redux/userSlice";
import { VisibilityTwoTone, VisibilityOffTwoTone } from "@mui/icons-material";

const SignIn = () => {
  const navigate = useNavigate();
  const [inputMode, setInputMode] = useState("phone");
  const [country, setCountry] = useState("61");
  const [phnNumber, setPhnNumber] = useState("");
  const [email, setEmail] = useState("");
  const [from, setFrom] = useState();
  const [fromScreen, setFromScreen] = useState(1);
  const [id, setId] = useState();

  const [active, setaActive] = useState(0);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const signInResponse = useSelector((state) => state.signupReducer.data);
  const googleLoginResponse = useSelector(
    (state) => state.googleLoginReducer.data
  );
  const dispatch = useDispatch();

  const handleOnChange = (value) => {
    setCountry(value);
  };

  console.log("from", from);
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      dispatch(setUser(userInfo));

      const payload = {
        googleId: userInfo.sub,
      };
      dispatch(googleLogin(payload));
    },
  });

  const onNextClick = () => {
    let payload = {};

    if (inputMode === "phone") {
      payload = {
        type: 1,
        countryCode: "+" + country,
        mobileNumber: phnNumber,
        signUpWithMobileOrEmail: 1,
      };
    } else if (inputMode === "email") {
      payload = {
        type: 1,
        mobileNumber: email,
        signUpWithMobileOrEmail: 2,
        password: password,
      };
    }

    dispatch(signupUser(payload));
  };

  useEffect(() => {
    console.log("signInResponse", signInResponse);
    if (signInResponse != null && signInResponse.status === 1) {
      setFrom(0);
      setId(signInResponse.data._id);
      gotoAnotherScreen();
    } else if (signInResponse != null && signInResponse?.status === 0) {
      // alert(signInResponse.message);
      alert("Wrong Credentials");
    }

    // else{
    //   alert("Wrong Credentials");
    // }
    // else if (signInResponse != null && signInResponse.status === 0) {
    //   alert("Wrong credentials");
    // }
  }, [signInResponse]);

  useEffect(() => {
    if (
      signInResponse != null &&
      signInResponse.otp_required === 0 &&
      signInResponse.login_type == "email"
    ) {
      setFrom(1);
      setId(signInResponse.data._id);
      console.log("Token ===> ", signInResponse.token);
      localStorage.setItem("token", signInResponse.token);
    } else if (signInResponse != null) {
      // alert(signInResponse.message);
      console.log("signInResponse.message", signInResponse.message);
    }
  }, [signInResponse]);

  console.log("signInResponse", signInResponse);
  useEffect(() => {
    if (googleLoginResponse != null && googleLoginResponse.status === 1) {
      setFrom(1);
      localStorage.setItem("token", googleLoginResponse.token);
      setId(googleLoginResponse.data._id);
    }
  }, [googleLoginResponse]);

  useEffect(() => {
    if (id != null) {
      if (from === 0) {
        navigate("/Otp", { state: { id, fromScreen } });
        dispatch(clearDataSignUp());
      } else if (from === 1) {
        navigate("/Project", { state: { id, fromScreen } });
        dispatch(clearDataSignUp());
      } else {
        navigate("/Project");
        dispatch(clearGoogleLoginData());
      }
    }
  }, [id, from, navigate, fromScreen, dispatch]);

  const gotoAnotherScreen = () => {};

  return (
    <>
      <div className="inner">
        <div className="sec_bg">
          <div className="logotype">
            <img src={ai_img} alt="ai_img" />
            <h3>Logotype</h3>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div className="signup_bg">
          <div className="text_btn">
            <h4>Don't have an account?</h4>
            <Button
              onClick={() => navigate("/SignUp")}
              style={{ cursor: "pointer" }}
            >
              Sign Up
            </Button>
          </div>

          <div className="signup_form">
            <Form>
              <h3>Sign In</h3>
              <div className="toggle-btns">
                <Button
                  variant={inputMode === "phone" ? "primary" : "secondary"}
                  onClick={() => {
                    setaActive(0);
                    setInputMode("phone");
                  }}
                  style={{
                    backgroundColor: active == 0 ? "#fff" : "transparent",
                    width: "50%",
                  }}
                >
                  Phone
                </Button>
                <Button
                  variant={inputMode === "email" ? "primary" : "secondary"}
                  onClick={() => {
                    setaActive(1);
                    setInputMode("email");
                  }}
                  style={{
                    backgroundColor: active == 1 ? "#fff" : "transparent",
                    width: "50%",
                  }}
                >
                  Email
                </Button>
              </div>

              <Form.Group className="mb-3 form_group">
                {inputMode === "phone" ? (
                  <>
                    <Form.Label>Phone Number</Form.Label>
                    <div className="btn_flex mb-3">
                      <PhoneInput
                        inputClass={"inputt-ph"}
                        containerStyle={{}}
                        searchClass="search-class"
                        disableSearchIcon={false}
                        enableTerritories
                        countryCodeEditable={false}
                        placeholder="Phone Number"
                        buttonStyle={{ width: "47px" }}
                        dropdownStyle={{ height: "150px" }}
                        enableSearch={true}
                        value={country}
                        onChange={handleOnChange}
                        inputProps={{
                          readOnly: true,
                        }}
                        enableAreaCodeStretch
                      />
                      <Form.Control
                        type="number"
                        className="mobilenumber"
                        placeholder="Phone Number"
                        value={phnNumber}
                        onChange={(v) => setPhnNumber(v.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      className="createInput"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ height: "44px" }}
                    />
                    <Form.Group
                      className="mb-4 form-contain"
                      style={{ marginTop: 16 }}
                      controlId="formBasicPassword"
                    >
                      <div className="inline">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          className="createInput"
                          placeholder="Password"
                          name="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          required
                        />
                        <button
                          type="button"
                          className="passwod_btn"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <VisibilityTwoTone />
                          ) : (
                            <VisibilityOffTwoTone />
                          )}
                        </button>
                      </div>
                    </Form.Group>
                  </>
                )}
              </Form.Group>

              <div className="next_btn">
                <Button onClick={onNextClick} style={{ cursor: "pointer" }}>
                  Next
                </Button>
                {/* Replace `next_icon` with actual icon or remove this line if unnecessary */}
              </div>
              {active == 1 && (
                <div
                  style={{
                    display: "flex",
                    marginTop: 16,
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  Forgot Password
                </div>
              )}

              <div className="separator">
                <span>or</span>
              </div>

              <div className="goog_fac_btn">
                <div
                  className="button google"
                  style={{ cursor: "pointer" }}
                  onClick={login}
                >
                  <img src={google} alt="google" /> Google
                </div>
                <Link
                  to="/"
                  className="button facebook"
                  style={{ cursor: "pointer" }}
                >
                  <img src={facebook} alt="facebook" /> Facebook
                </Link>
              </div>
            </Form>
          </div>

          <p>
            Protected by reCAPTCHA and subject to the Rhombus <br />
            <b>Privacy Policy</b> and <b>Terms of Service.</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignIn;
