import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDataCreateProject,
  createProject,
} from "../../redux/CreateProjectSlice";
import { useNavigate } from "react-router-dom";
import { getCountryList } from "../../redux/GetCountrySlice";
import { getStateList } from "../../redux/GetStateListSlice";
import { getCityList } from "../../redux/GetCityListSlice";
import Header from "../Header/Header";
import { getCompanyList } from "../../redux/GetCompanyListSlice";

const CreateProject = () => {
  const [projectName, setProjectName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [states, setStates] = useState("");
  const [countryCode, setCountryCode] = useState("AU");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const addProjectResponse = useSelector(
    (state) => state.createProjectReducer.data
  );

  const countryResponse = useSelector(
    (state) => state.getCountryListReducer.data
  );

  const stateResponse = useSelector((state) => state.getStateListReducer.data);

  const cityResponse = useSelector((state) => state.getCityListReducer.data);

  const companyResponse = useSelector(
    (state) => state.getCompanyListReducer.data
  );

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getCompanyList());
  }, []);

  useEffect(() => {
    if (countryCode != "") {
      dispatch(getStateList(countryCode));
    }
  }, [countryCode]);

  useEffect(() => {
    if (countryCode && selectedStateCode) {
      dispatch(getCityList({ countryCode, stateCode: selectedStateCode }));
    }
  }, [countryCode, selectedStateCode]);

  useEffect(() => {
    if (countryResponse) {
      setCountry(countryResponse.data);
    }
  }, [countryResponse]);

  useEffect(() => {
    if (companyResponse) {
      setCompanyName(companyResponse?.data);
    }
  }, [companyResponse]);

  useEffect(() => {
    if (stateResponse) {
      setStates(stateResponse?.data);
    }
  }, [stateResponse, countryCode]);

  useEffect(() => {
    if (cityResponse) {
      setCity(cityResponse?.data);
    }
  }, [cityResponse, countryCode, states]);

  const addProject = () => {
    if (projectName.length < 1) {
      alert("Please enter project name!");
    } else if (city.length < 1) {
      alert("Please enter city!");
    } else if (zip.length < 1) {
      alert("Please enter Zip Code/Postal Code code!");
    } else if (address.length < 1) {
      alert("Please enter street Address!");
    } else {
      const payload = {
        projectName: projectName,
        companyName: selectedCompany,
        country: selectedCountry,
        city: selectedCity,
        state: selectedStateName,
        streetAddress: address,
        zipCode: zip,
      };

      dispatch(createProject(payload));
    }
  };

  useEffect(() => {
    if (addProjectResponse != null && addProjectResponse.status == 1) {
      navigation(-1);
      dispatch(clearDataCreateProject());
    }
  }, [addProjectResponse]);

  const countryChangeHandler = (e) => {
    const selectedCountryObj = country.find(
      (c) => c.isoCode === e.target.value
    );
    setCountryCode(e.target.value);
    setSelectedCountry(selectedCountryObj?.name);
  };

  const stateChangeHandler = (e) => {
    const selectedStateObj = states.find((s) => s.isoCode === e.target.value);
    setSelectedStateCode(e.target.value);
    setSelectedStateName(selectedStateObj.name);
  };

  return (
    <div className="sidebar_mar">
      <Header />
      <form>
        <div className="add_project_style">
          <h2>Add Projects</h2>
          <p>General Information</p>
        </div>

        <div className="two_input_flex">
          <div className="label_input_flex">
            <label>Company Name</label>

            <select
              className="project-select"
              onChange={(v) => setSelectedCompany(v.target.value)}
            >
              <option selected="true" disabled="disabled">
                Select Company
              </option>
              {companyName &&
                companyName?.map((c, index) => (
                  <option value={c.name} key={index}>
                    {c.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="label_input_flex">
            <label>Project Name</label>
            <input
              type="text"
              placeholder="Project Name"
              value={projectName}
              onChange={(v) => setProjectName(v.target.value)}
            />
          </div>
        </div>

        <div className="two_input_flex">
          <div className="label_input_flex">
            <label>Country</label>
            <select
              className="project-select"
              value={countryCode}
              onChange={(v) => countryChangeHandler(v)}
            >
              {/* <option selected="true" disabled="disabled">
                Select Country
              </option> */}
              {country &&
                country?.map((c, index) => (
                  <option value={c.isoCode} key={index}>
                    {c.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="label_input_flex">
            <label>State</label>
            <select className="project-select" onChange={stateChangeHandler}>
              <option selected="true" disabled="disabled">
                Select State
              </option>
              {states &&
                states?.map((s, index) => (
                  <option value={s.isoCode} key={index}>
                    {s.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="two_input_flex">
          <div className="label_input_flex">
            <label>City</label>
            <select
              className="project-select"
              onChange={(v) => setSelectedCity(v.target.value)}
            >
              <option selected="true" disabled="disabled">
                Select City
              </option>
              {city &&
                city?.map((c, index) => (
                  <option value={c.code} key={index}>
                    {c.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="label_input_flex">
            <label>Street Address</label>
            <input
              type="text"
              placeholder="Street Address"
              value={address}
              onChange={(v) => setAddress(v.target.value)}
            />
          </div>
        </div>

        <div className="zip_flex">
          <label>Zip Code/Postal Code</label>
          <input
            type="text"
            placeholder="Zip Code/Postal Code"
            value={zip}
            onChange={(v) => setZip(v.target.value)}
          />
        </div>
      </form>

      <div className="folder_content">
        <h4>Project folder associations</h4>
        <p>
          Select which project folders data is pulled from for each service you
          are<span> integrated</span> with.
        </p>
      </div>

      <div className="cancel_create_btn">
        <Button className="cancel_btn" style={{ cursor: "pointer" }}>
          Cancel
        </Button>
        <Button
          className="create_btn"
          onClick={() => addProject()}
          style={{ cursor: "pointer" }}
        >
          Create Project
        </Button>
      </div>
    </div>
  );
};

export default CreateProject;
