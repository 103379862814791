import React, { useState } from "react";
import notification from "../../assets/img/notification.svg";
import user_icon from "../../assets/img/user_icon.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import down_icon from "../../assets/img/down_icon.svg";

const Header = () => {
  const navigation = useNavigate();
  const user = useSelector((state) => state.userReducer.user);

  const [selectedItem, setSelectedItem] = useState();

  const handleSelect = (eventKey) => {
    setSelectedItem(eventKey);
  };

  return (
    <div className="header header-with-data">
      <div className="user-info">
        <img
          src={notification}
          style={{ cursor: "pointer" }}
          alt="notification"
          className="notification-icon"
        />
        {user?.picture ? (
          <img
            src={user?.picture}
            alt="user_icon "
            className="user-icon google-img"
            style={{ cursor: "pointer" }}
            onClick={() => navigation("/ProfileHeader")}
          />
        ) : (
          <img
            src={user_icon}
            alt="user_icon"
            className="user-icon"
            style={{ cursor: "pointer" }}
            onClick={() => navigation("/ProfileHeader")}
          />
        )}
        <div className="user-data">
          {/* <span className="name">{user?.name ? user?.name : "Mobile User"}</span> */}
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <img src={down_icon} alt="down_icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="ContactUs">
                <NavLink to="/ContactUs">Contact Us</NavLink>
              </Dropdown.Item>
              <Dropdown.Item eventKey="About">
                <NavLink to="/About">About Us</NavLink>
              </Dropdown.Item>
              <Dropdown.Item eventKey="AddCompany">
                <NavLink to="/Company">Company</NavLink>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <span className="username">{user?.email}</span> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
