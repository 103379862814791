import React from 'react';
import ai_img from "../../assets/img/ai_img.svg";
import { Link } from 'react-router-dom';

function HeaderHome() {

    return (
        <>
            <header className="App-header">
                <nav className="navbar">
                    <div className="logo">
                        <img src={ai_img} alt='ai_img' />
                        Logotype</div>
                    <div className="nav-links">
                        <Link to="#">News</Link>
                        <Link to="SignIn" className='start_btn'>Log In</Link>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default HeaderHome;
