import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const uploadFile = createAsyncThunk(
  "fileUpload/uploadFile",
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState: {
    uploading: false,
    uploadSuccess: false,
    uploadError: null,
    uploadedFile: null,
  },
  reducers: {
    resetUploadState: (state) => {
      state.uploading = false;
      state.uploadSuccess = false;
      state.uploadError = null;
      state.uploadedFile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.uploading = true;
        state.uploadSuccess = false;
        state.uploadError = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.uploading = false;
        state.uploadSuccess = true;
        state.uploadedFile = action.payload;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.uploading = false;
        state.uploadSuccess = false;
        state.uploadError = action.payload;
      });
  },
});

export const { resetUploadState } = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
