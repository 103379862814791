import { configureStore } from "@reduxjs/toolkit";
import signupReducer from "./signupSlice";
import otpVerificationReducer from "./OtpSlice";
import createProjectReducer from "./CreateProjectSlice";
import getProjectListReducer from "./GetProjectListSlice";
import chatHistoryReducer from "./GetChatHistorySlice";
import askQuestionReducer from "./AskQuestionSlice";
import googleLoginReducer from "./GoogleSignInSlice";
import uploadFileReducer from "./PdfUploadApiSlice";
import fileUploadReducer from "./fileUploadSlice";
import askQuestionFromPdfReducer from "./AskQuestionFromPdfSlice";
import getDocumentListReducer from "./GetDocumentListSlice";
import userReducer from "./userSlice";
import getCountryListReducer from "./GetCountrySlice";
import getStateListReducer from "./GetStateListSlice";
import getCityListReducer from "./GetCityListSlice";
import createCompanyReducer from "./CreateCompanySlice";
import getCompanyListReducer from "./GetCompanyListSlice";
import getProfileReducer from "./GetProfileSlice";
import editProfileReducer from "./EditProfileSlice";
import getProjectReducer from "./GetProjectSlice";
import updateProjectReducer from "./UpdateProjectSlice";
import DeleteFileReducer from "./DeleteFileSlice";
const store = configureStore({
  reducer: {
    signupReducer: signupReducer,
    otpVerificationReducer: otpVerificationReducer,
    createProjectReducer: createProjectReducer,
    getProjectListReducer: getProjectListReducer,
    chatHistoryReducer: chatHistoryReducer,
    askQuestionReducer: askQuestionReducer,
    askQuestionFromPdfReducer: askQuestionFromPdfReducer,
    googleLoginReducer: googleLoginReducer,
    uploadFileReducer: uploadFileReducer,
    fileUpload: fileUploadReducer,
    getDocumentListReducer: getDocumentListReducer,
    userReducer: userReducer,
    getCountryListReducer: getCountryListReducer,
    getStateListReducer: getStateListReducer,
    getCityListReducer: getCityListReducer,
    createCompanyReducer: createCompanyReducer,
    getCompanyListReducer: getCompanyListReducer,
    getProfileReducer: getProfileReducer,
    editProfileReducer: editProfileReducer,
    getProjectReducer: getProjectReducer,
    updateProjectReducer: updateProjectReducer,
    DeleteFileReducer: DeleteFileReducer,
  },
});

export default store;
