import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, getProfileDataApi } from "../utils/Constants";

export const getProfile = createAsyncThunk("getProfile", async () => {
  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
    };
    const response = await axios.get(ApiBaseUrl + getProfileDataApi, config);
    // console.log("response",response)
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

const GetProfileSlice = createSlice({
  name: "getProfileReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearGetProfileData: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getProfile.rejected, (state) => {
        state.isError = false;
      });
  },
});

export const { clearGetProfileData } = GetProfileSlice.actions;
export default GetProfileSlice.reducer;
