import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigation = useNavigate();
  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>404 - Page Not Found</h1>
      <p style={{ fontSize: "20px",marginTop:"20px", marginBottom:"20px" }}>Sorry, the page you are looking for does not exist.</p>
      <button onClick={() => navigation(-1)}>Go Back</button>
    </div>
  );
};

export default NotFound;
