import React from 'react';

function TermCondition() {

    return (
        <>
            <section>
                <div className='content_section'>
                    <h1>Terms & Conditions</h1>

                    <ul>
                        <div style={{ marginBottom: 35 }}>
                            <ol>1. Introduction</ol>

                            <li className='list_item_first'>Overview of the AI Web Application:</li>
                            <li className='list_secound'>This AI-powered web application leverages advanced machine learning algorithms to provide users with [specific functionalities, e.g., personalized recommendations, automated content creation, data analysis, etc.]. It’s designed to enhance user experience by delivering intelligent, data-driven results in real-time.</li>

                            <li className='list_item_first'>Purpose and Benefits:</li>
                            <li className='list_secound'>The application is built to help users [solve specific problems, save time, improve accuracy, etc.], by utilizing cutting-edge AI technology.</li>
                        </div>

                        <div style={{ marginBottom: 35 }}>
                            <ol>2. Core Technology</ol>

                            <li className='list_item_first'>Machine Learning Models:</li>
                            <li className='list_secound'>The AI web application uses [specific types of machine learning models, e.g., natural language processing (NLP), neural networks, etc.] to process and analyze large datasets. These models are trained on diverse data to ensure accurate and reliable outcomes.</li>

                            <li className='list_item_first'>Data Processing:</li>
                            <li className='list_secound'>Incoming data is processed through a series of algorithms that clean, sort, and prepare it for analysis. The AI then applies predictive modeling or pattern recognition techniques to generate actionable insights.</li>

                            <li className='list_item_first'>Continuous Learning:</li>
                            <li className='list_secound'>The AI system continually learns from new data, refining its models over time to improve accuracy and adaptability.</li>
                        </div>

                        <div style={{ marginBottom: 35 }}>
                            <ol>3. User Interaction</ol>

                            <li className='list_item_first'>Simple User Interface:</li>
                            <li className='list_secound'>The web application features an intuitive user interface that allows users to interact with the AI effortlessly. Users can input data, ask questions, or request specific outputs through straightforward forms or commands.</li>

                            <li className='list_item_first'>Personalization:</li>
                            <li className='list_secound'>The AI tailors its responses and suggestions based on user behavior and preferences. Over time, it learns from user interactions to offer increasingly personalized experiences.</li>

                            <li className='list_item_first'>Real-Time Feedback:</li>
                            <li className='list_secound'>The application provides real-time feedback and results, ensuring that users receive immediate value from their interactions.</li>

                        </div>
                    </ul>
                </div>
            </section>
        </>
    );
}

export default TermCondition;
