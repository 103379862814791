// src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, getCompanyListApi } from "../utils/Constants";

export const getCompanyList = createAsyncThunk(
  "getCompanyList",
  async (data = null) => {
    try {
      const token = localStorage.getItem("token");
      // console.log("Token", token);
      const config = {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      };

      const response = await axios.post(
        ApiBaseUrl + getCompanyListApi,
        data,
        config
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const GetCompanyListSlice = createSlice({
  name: "getCompanyListReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearGetCompanyListData: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getCompanyList.rejected, (state) => {
        state.isError = false;
      });
  },
});

export const { clearDataCreateCompany } = GetCompanyListSlice.actions;
export default GetCompanyListSlice.reducer;
