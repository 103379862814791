import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, getCityListApi } from "../utils/Constants";

export const getCityList = createAsyncThunk(
  "getCityList",
  async ({ countryCode, stateCode }) => {
    try {
      const token = localStorage.getItem("token");
      // console.log("Token", token);
      const config = {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
        },
      };

      const response = await axios.post(
        ApiBaseUrl + getCityListApi,
        { countryCode, stateCode },
        config
      );
      // console.log("first response", response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const GetCityListSlice = createSlice({
  name: "getCityListReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearGetCityListData: (City) => {
      City.data = null;
      City.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCityList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCityList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getCityList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export const { clearDataCreateCity } = GetCityListSlice.actions;
export default GetCityListSlice.reducer;
