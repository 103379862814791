import GoogleDrivePicker from "google-drive-picker";
import { useEffect, useState } from "react";
const GoogleDrivePickerApp = () => {
  const [authTocken, setauthTocken] = useState("");
  const [openPicker, authRes] = GoogleDrivePicker();

  const handlePickerOpen = () => {
    openPicker({
      clientId:
        "195779094131-ip4ppi06hggin03e4r27ii1jmjq4b89e.apps.googleusercontent.com",
      developerKey: "AIzaSyDiM0qX4mjaHb6zdKV6QZMFWSrgNPES13M",
      viewId: "DOCS",
      token: authTocken,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        } else if (data.docs && data.docs.length > 0) {
          console.log(data);
        }
      },
    });
  };

  useEffect(() => {
    if (authRes) {
      setauthTocken(authRes.access_token);
    }
  }, [authRes]);

  console.log("drive picker authRes", authRes);

  return (
    <div>
      <button onClick={handlePickerOpen}>Open Google Drive Picker</button>
      {/* {authRes && <div>Authenticated: {authRes.access_token}</div>} */}
    </div>
  );
};

export default GoogleDrivePickerApp;
