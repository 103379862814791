import React from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ContactUs = () => {
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First name is required")
      .min(2, "First name should be at least 2 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .min(2, "Last name should be at least 2 characters"),
    email: Yup.string()
      .required("Email address is required")
      .email("Invalid email address"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description should be at least 10 characters"),
  });

  return (
    <div className="inner contact_us sidebar_mar">
      <div className="signup_bg">
        <div className="mb-4 signup_form ">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              alert(`Form Submitted Successfully!`);
              resetForm();
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <h3>Contact Us</h3>
                <div className="d_flex">
                  <Form.Group
                    className="mb-3 form_group"
                    controlId="formBasicFirstName"
                  >
                    <Form.Label>First name</Form.Label>
                    <Field
                      name="firstName"
                      type="text"
                      placeholder="First name"
                      className="createInput form-control"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 form_group"
                    controlId="formBasicLastName"
                  >
                    <Form.Label>Last name</Form.Label>
                    <Field
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                      className="createInput form-control"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </div>
                <Form.Group
                  className="mb-3 form_group"
                  controlId="formBasicEmail"
                  style={{ marginBottom: 12 }}
                >
                  <Form.Label>Email Address</Form.Label>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    className="createInput form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 form_group"
                  controlId="formBasicDescription"
                >
                  <Form.Label>Description</Form.Label>
                  <Field
                    name="description"
                    as="textarea"
                    placeholder="Description"
                    rows="5"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <div className="next_btn">
                  <Button type="submit" style={{ marginTop: 10 }}>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
