import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import circle_download from "../../assets/img/circle_download.svg";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/GetProfileSlice";

const ProfileHeader = () => {
  const [profileData, setProfileData] = useState({});
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const profileResponse = useSelector((state) => state.getProfileReducer.data);

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  useEffect(() => {
    if (profileResponse != null && profileResponse.status == 1) {
      setProfileData(profileResponse.data);
    }
  }, [profileResponse]);

  console.log("profileData", profileData);

  return (
    <div className="sidebar_mar">
      <Header />

      <div className="profile_btn_styles">
        <h3>Profile</h3>
        <Button type="button" onClick={() => navigation("/EditProfile")}>
          Edit Profile
        </Button>
      </div>

      <div className="profile_content">
        <div className="img_download">
          <img src={circle_download} alt="circle_download" />
        </div>

        <div className="profile_ditz">
          <div className="name_email_Number">
            <p>Name:</p>
            <h4>
              {profileData?.firstName?.length > 2
                ? profileData?.firstName
                : "Not Available"}{" "}
              {profileData?.lastName?.length > 2
                ? profileData?.lastName
                : "Not Available"}
            </h4>
          </div>

          <div className="name_email_Number">
            <p>Email Address:</p>
            <h4>
              {profileData?.email?.length > 2
                ? profileData?.email
                : "Not Available"}
            </h4>
          </div>

          <div className="name_email_Number">
            <p>Company Name:</p>
            <h4>
              {profileData?.companyName?.length > 2
                ? profileData?.companyName
                : "Not Available"}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
