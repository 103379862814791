import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import add_circke from "../../assets/img/add_circle.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDataCreateCompany,
  createCompany,
} from "../../redux/CreateCompanySlice";
import { useNavigate } from "react-router-dom";

const Company = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");

  const navigation = useNavigate();
  const dispatch = useDispatch();
  const addCompanyResponse = useSelector(
    (state) => state.createCompanyReducer.data
  );

  const addComapny = () => {
    if (companyName.length < 1) {
      alert("Please enter company name");
    } else if (companyAddress.length < 1) {
      alert("Please enter company address");
    } else {
      const payload = {
        name: companyName,
        address: companyAddress,
      };
      dispatch(createCompany(payload));
    }
  };

  useEffect(() => {
    if (addCompanyResponse != null && addCompanyResponse.status == 1) {
      // alert("Company added successfully");
      navigation(-1);
      dispatch(clearDataCreateCompany());
    }
  }, [addCompanyResponse]);
  return (
    <>
      <div className="inner contact_us sidebar_mar">
        <div className="signup_bg">
          <div className="mb-4 signup_form ">
            <Form>
              <h3>Company</h3>
              <Form.Group
                className="mb-3 form_group"
                style={{ marginBottom: 12 }}
                controlId="formBasicEmail"
              >
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  className="createInput"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 form_group"
                style={{ marginBottom: 12 }}
                controlId="formBasicEmail"
              >
                <Form.Label>Company Address</Form.Label>
                <Form.Control
                  // type="text"
                  as="textarea"
                  placeholder="Address"
                  className="createInput"
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group
                                className="mb-3 form_group"
                                style={{ marginBottom: 12 }}
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Company Document</Form.Label>
                                <div>
                                    <img src={add_circke} alt="add_circke" />
                                </div>
                            </Form.Group> */}
              <div className="next_btn">
                <Button onClick={addComapny} style={{ marginTop: 10 }}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
