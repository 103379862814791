// import React, { useEffect, useState } from "react";
// import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import Fuse from "fuse.js"; // Import fuzzy search library
// const PdfViewer = ({ pdfUrl, textData, onClose }) => {
//   const [isDocumentLoaded, setDocumentLoaded] = useState(false);
//   const [fuzzyMatches, setFuzzyMatches] = useState([]);
  
//   const handleDocumentLoad = () => setDocumentLoaded(true);

//   // Preprocess text data to break it into smaller chunks
//   const removeQuotes = textData.replaceAll('"', "").trim();
//   const splitArr = removeQuotes?.split(/\s+/);

//   const trimmedArray = [];
//   const trimCount = 5;
//   for (let i = 0; i < splitArr.length; i += trimCount) {
//     trimmedArray.push(splitArr.slice(i, i + trimCount).join(" "));
//   }

//   if (
//     trimmedArray.length > 1 &&
//     trimmedArray[trimmedArray.length - 1].split(" ").length === 1
//   ) {
//     trimmedArray[trimmedArray.length - 2] += ` ${trimmedArray.pop()}`;
//   }

//   // Fuse.js configuration for fuzzy matching
//   const fuse = new Fuse(trimmedArray, {
//     includeScore: true,
//     threshold: 0.3, // Adjust for sensitivity (0 = exact, 1 = very lenient)
//     minMatchCharLength: 2,
//     findAllMatches: true,
//   });

//   const searchAndHighlight = (pdfText) => {
//     const results = fuse.search(pdfText);
//     const matches = results.map((result) => result.item);
//     setFuzzyMatches(matches);
//   };

//   const defaultLayoutPluginInstance = defaultLayoutPlugin({
//     toolbarPlugin: {
//       searchPlugin: {
//         keyword: fuzzyMatches.length > 0 ? fuzzyMatches : trimmedArray,
//         onHighlightKeyword: (props) => {
//           props.highlightEle.style.backgroundColor = "rgba(255,240, 0, .4)";
//         },
//       },
//     },
//   });

//   const { toolbarPluginInstance } = defaultLayoutPluginInstance;
//   const { searchPluginInstance } = toolbarPluginInstance;
//   const { highlight } = searchPluginInstance;

//   useEffect(() => {
//     if (isDocumentLoaded) {
//       highlight(fuzzyMatches.length > 0 ? fuzzyMatches : trimmedArray);
//     }
//   }, [isDocumentLoaded, fuzzyMatches]);

//   if (!textData || textData.trim().length === 0) {
//     return (
//       <div className="pdf-viewer">
//         <div className="pdf-header">
//           <button onClick={onClose}>Close</button>
//         </div>
//         <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
//           <div
//             style={{
//               height: "100vh",
//               width: "100%",
//               margin: "0 auto 20px auto",
//               paddingBottom: "30px",
//             }}
//           >
//             <Viewer
//               fileUrl={pdfUrl}
//               plugins={[defaultLayoutPluginInstance]}
//             />
//           </div>
//         </Worker>
//       </div>
//     );
//   }

//   return (
//     <div className="pdf-viewer">
//       <div className="pdf-header">
//         <button onClick={onClose}>Close</button>
//       </div>
//       <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
//         <div
//           style={{
//             height: "100vh",
//             width: "100%",
//             margin: "0 auto 20px auto",
//             paddingBottom: "30px",
//           }}
//         >
//           <Viewer
//             fileUrl={pdfUrl}
//             plugins={[defaultLayoutPluginInstance]}
//             onDocumentLoad={handleDocumentLoad}
//           />
//         </div>
//       </Worker>
//     </div>
//   );
// };

// export default PdfViewer;


//   // Prepare keywords when OpenAI response is received
import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Fuse from "fuse.js"; // Fuzzy search library

const PdfViewer = ({ pdfUrl, textData, onClose }) => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const [fuzzyMatches, setFuzzyMatches] = useState([]);
  
  const handleDocumentLoad = () => setDocumentLoaded(true);

  // Process text data for fuzzy matching by splitting into chunks
  const prepareSearchTerms = (data) => {
    const removeQuotes = data.replaceAll('"', "").trim();
    const wordsArray = removeQuotes?.split(/\s+/);

    const trimmedArray = [];
    const trimCount = 4; // Adjust the chunk size if necessary
    for (let i = 0; i < wordsArray.length; i += trimCount) {
      trimmedArray.push(wordsArray.slice(i, i + trimCount).join(" "));
    }

    if (
      trimmedArray.length > 1 &&
      trimmedArray[trimmedArray.length - 1].split(" ").length === 1
    ) {
      trimmedArray[trimmedArray.length - 2] += ` ${trimmedArray.pop()}`;
    }
    return trimmedArray;
  };

  const trimmedArray = prepareSearchTerms(textData);

  // Fuse.js configuration for fuzzy matching
  const fuse = new Fuse(trimmedArray, {
    includeScore: true,
    threshold: 0.9, // Adjust for sensitivity (0 = exact match, 1 = very lenient)
    minMatchCharLength: 2,
    findAllMatches: true,
  });

  // Function to search and highlight matches
  const searchAndHighlight = (pdfText) => {
    const results = fuse.search(pdfText);
    const matches = results.map((result) => result.item);
    setFuzzyMatches(matches);
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      searchPlugin: {
        keyword: fuzzyMatches.length > 0 ? fuzzyMatches : trimmedArray,
        onHighlightKeyword: (props) => {
          props.highlightEle.style.backgroundColor = "rgba(255,240,0,0.4)"; // Highlight color
        },
      },
    },
  });

  // Destructure searchPlugin for highlighting control
  const { toolbarPluginInstance } = defaultLayoutPluginInstance;
  const { searchPluginInstance } = toolbarPluginInstance;
  const { highlight } = searchPluginInstance;

  useEffect(() => {
    if (isDocumentLoaded) {
      // Apply highlights when the document is loaded
      highlight(fuzzyMatches.length > 0 ? fuzzyMatches : trimmedArray);
    }
  }, [isDocumentLoaded, fuzzyMatches]);

  if (!textData || textData.trim().length === 0) {
    return (
      <div className="pdf-viewer">
        <div className="pdf-header">
          <button onClick={onClose}>Close</button>
        </div>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
          <div
            style={{
              height: "100vh",
              width: "100%",
              margin: "0 auto 20px auto",
              paddingBottom: "30px",
            }}
          >
            <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
          </div>
        </Worker>
      </div>
    );
  }

  return (
    <div className="pdf-viewer">
      <div className="pdf-header">
        <button onClick={onClose}>Close</button>
      </div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <div
          style={{
            height: "100vh",
            width: "100%",
            margin: "0 auto 20px auto",
            paddingBottom: "30px",
          }}
        >
          <Viewer
            fileUrl={pdfUrl}
            plugins={[defaultLayoutPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
          />
        </div>
      </Worker>
    </div>
  );
};

export default PdfViewer;
