import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, getStateListApi } from "../utils/Constants";

export const getStateList = createAsyncThunk(
  "getStateList",
  async (countryCode) => {
    try {
      const token = localStorage.getItem("token");
      // console.log("Token", token);
      const config = {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "*",
        },
      };

      const response = await axios.post(
        ApiBaseUrl + getStateListApi,
        { countryCode: countryCode },
        config
      );
      // console.log("first response", response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const GetStateListSlice = createSlice({
  name: "getStateListReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearGetStateListData: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStateList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStateList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getStateList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export const { clearDataCreateState } = GetStateListSlice.actions;
export default GetStateListSlice.reducer;
