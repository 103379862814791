import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ApiBaseUrl,
  getProjectListApi,
  updateProjectApi,
} from "../utils/Constants";

export const updateProject = createAsyncThunk(
  "updateProject",
  async (projectData) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      };
      const response = await axios.put(
        ApiBaseUrl + updateProjectApi,
        projectData,
        config
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const UpdateProjectSlice = createSlice({
  name: "updateProjectReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearDataUpdateProject: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateProject.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearDataUpdateProject } = UpdateProjectSlice.actions;
export default UpdateProjectSlice.reducer;
