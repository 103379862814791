import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, editProfileApi } from "../utils/Constants";

export const editProfile = createAsyncThunk("editProfile", async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };
    const response = await axios.put(
      ApiBaseUrl + editProfileApi,
      payload,
      config
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

const EditProfileSlice = createSlice({
  name: "editProfileReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearEditProfileData: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(editProfile.rejected, (state) => {
        state.isError = false;
      });
  },
});
export const { clearEditProfileData } = EditProfileSlice.actions;
export default EditProfileSlice.reducer;
