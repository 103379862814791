import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import next_icon from "../../assets/img/next_icon.svg";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyList } from "../../redux/GetCompanyListSlice";

const AddCompany = () => {
  const [companyName, setCompanyName] = useState("");

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const companyResponse = useSelector(
    (state) => state.getCompanyListReducer.data
  );

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  useEffect(() => {
    console.log("companyResponse", companyResponse);
    if (companyResponse) {
      setCompanyName(companyResponse?.data);
    }
  }, [companyResponse]);

  return (
    <div className="sidebar_mar">
      <Header />
      <div className="project_btn_style">
        <h2>Company</h2>
        <Button type="button" onClick={() => navigation("/AddCompany")}>
          Add New
        </Button>
      </div>

      {companyName &&
        companyName?.map((data, index) => (
          <>
            <div
              className="magic_content_btn"
              style={{ cursor: "pointer" }}
              onClick={() => navigation("/Company")}
            >
              <div className="label_input_flex">
                <h4>{data.name}</h4>
              </div>

              <div className="active_btn_content">
                <div className="active_column">
                  <p style={{ fontSize: 16, color: "#000" }}>{data.address}</p>
                </div>
                <img src={next_icon} alt="next_icon" />
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default AddCompany;
