//BASE URL

export const ApiBaseUrl = "https://api.youraiweb.com.au";
// export const ApiBaseUrl = "http://sn-cast-api.sparcknet.com:3001/";

//Api Names
export const signupApi = "/v1/user/login";
// export const signupApi = "api/auth/signin";
export const otpApi = "/v1/user/verifyOtp";
export const createProjectApi = "/v1/user/addProject";
export const getProjectListApi = "/v1/user/getProjectList";
export const getChatHistoryApi = "/v1/user/getChatHistory";
export const askQuestionApi = "/v1/user/askQuestion";
export const googleLoginApi = "/v1/user/signUpGoogle";
export const pdfUploadApi = "/v1/user/store-pdf";
export const pdfUploadApiDrive = "/v1/user/store-pdf-drive";
export const askQuestionFromPdfApi = "/v1/user/get-pdf-answer";
export const getDocumentListApi = "/v1/user/getChatList";
export const getCountryListApi = "/v1/user/getCountries";
export const getStateListApi = "/v1/user/getStates";
export const getCityListApi = "/v1/user/getCities";
export const createCompanyApi = "/v1/user/createCompany";
export const getCompanyListApi = "/v1/user/getCompany";
export const getProfileDataApi = "/v1/user/profile";
export const editProfileApi = "/v1/user/updateProfile";
export const getProjectApi = "/v1/user/getProject?projectId=";
export const updateProjectApi = "/v1/user/updateProject";
export const deleteProjectFileApi = "/v1/user/deleteProjectChat";
//Google drive

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const API_KEY = process.env.REACT_APP_API_KEY;

export const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];
export const SCOPES = "https://www.googleapis.com/auth/drive.readonly";
